<template>
<div class="Preview" style="display: block;min-height: 400px;max-width: 800px;">
	
	<quill-editor
		v-model="EditContent" 
		ref="myQuillEditor" 
		:options="editorOption" 
		@blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
		@change="onEditorChange($event)">
	</quill-editor>
	
	<el-upload style="display: none;"
	    :show-upload-list="false"
	    :format="['jpg','jpeg','png','gif']"
	    :max-size="2048"
	    action="https://jsonplaceholder.typicode.com/posts/"
	    :show-file-list="false" :on-change="getFileChange" :limit="100" :auto-upload="false">
		<em class="ups"></em>
	</el-upload>
	
</div>
</template>

<script>
	import Vue from 'vue'
	import {Upload} from 'element-ui'
	import VueQuillEditor, { Quill } from 'vue-quill-editor'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	Vue.use(VueQuillEditor)
	export default {
	  name: 'NewsContent',
	  props: {
		content:{
			type:String,
			default:''
		},
	  },
	  data() {
	      return {
				editorOption:{
					theme: 'snow',
					boundary: document.body, 
					modules: {
						toolbar:{
							container: [
								['bold', 'italic', 'underline'],
								[{ 'size': ['small', false, 'large', 'huge'] }],
								[{ 'color': [] }, { 'background': [] }],
								[{ 'align': [] }],
								['link', 'image']
							],  // 工具栏
							handlers: {
								'image': function (value) {
									if (value) {
										 document.querySelector('.ups').click()
									} else {
										this.quill.format('image', false);
									}
								}
							}
						},
					   
					   
					},
					placeholder: '输入详情...',
					readOnly: false,
			  },
			  EditContent:'',
			  uillUpdateImg:false,//根据图片上传状态来确定是否显示loading动画
			  serverUrl:'', //上传的图片服务器地址
	      }
	    },
	  components: {
		  "el-upload":Upload
	  },
	  created() {
		  this.EditContent = this.content
	  },
	  methods:{
		onEditorBlur(e){},
		onEditorFocus(e){},
		onEditorChange(e){
			this.$emit('UpNewsContent',e.html)
		},
		getFileChange(file, fileList) {
			this.getBase64(file.raw).then(res => {
				this.uploadPic(res)
			});
		},
		getBase64(file) {
		      return new Promise(function(resolve, reject) {
				let reader = new FileReader();
				let imgResult = "";
				reader.readAsDataURL(file);
				reader.onload = function() {
				  imgResult = reader.result;
				};
				reader.onerror = function(error) {
				  reject(error);
				};
				reader.onloadend = function() {
				  resolve(imgResult);
				};
			  });
		},
		uploadPic(_b64){
			  let that = this
			  let data = {
				Service:'Goods',
				Class: 'File',
				Action: 'Base64Upload',
				Base64:_b64
			  }
			  this.$post(that.$store.getters.getApiHost,data)
			  .then((res) => {
				
				if(res.ErrorId != 0){
					that.$message(res.Msg)
					return
				}
				// 获取光标所在位置
				let quill = that.$refs.myQuillEditor.quill
				let length = quill.getSelection().index;
				// 插入图片，服务器返回的图片链接地址
				quill.insertEmbed(length, 'image', res.Data.Url)
				// 调整光标到最后
				quill.setSelection(length + 1)
				
				
			  })
			  .catch(function (response) {
				that.$message('上传出错')
			  })
		},
	  }
	}
</script>

<style>
	.ql-editor img{
		width: 100%;
	}
</style>
