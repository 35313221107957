<template>
	<div class="GoodsInfoSSS">
		
		<div class="Top">
			<div class="Left">
				新闻资讯/文章详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li class="Sel" v-if="News.Id != 'add'">
					<span class="Sel">资讯ID</span>
					<em class="Sel">
						{{News.Id}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">*标题</span>
					<em  class="Sel">
						<el-input v-model="News.Title" style="width: 400px;"></el-input>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">子标题</span>
					<em  class="Sel">
						<el-input size="mini" v-model="News.SubTitle" style="width: 400px;"></el-input>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">*展示平台</span>
					<em  class="Sel">
						<el-select v-model="News.Platform">
							<el-option :label="'全平台'" :value="'all'"></el-option>
							<el-option :label="'电脑B端'" :value="'pc_b'"></el-option>
							<el-option :label="'电脑C端'" :value="'pc_c'"></el-option>
							<el-option :label="'移动C端'" :value="'mobi_c'"></el-option>
						</el-select>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">*分类</span>
					<em class="Sel">
						<el-select placeholder="设置分类" v-model="News.CategoryId">
							<template v-for="cat in CategoryList">
								<el-option :label="cat.Name" :value="cat.Id" :key="cat.Id"></el-option>
							</template>
					    </el-select>
					</em>
				</li>
				
				
				<li class="Sel">
					<span class="Sel">主图</span>
					<em  class="Sel">
						<el-upload
						  class="avatar-uploader"
						  action="https://jsonplaceholder.typicode.com/posts/"
						  :show-file-list="false" :on-change="getFile" :limit="1" :auto-upload="false">
						  <img v-if="News.Thumb.length > 0" :src="News.Thumb" class="avatar">
						  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</em>
				</li>
				
				
				<li class="Sel">
					<span class="Sel">*详情</span>
					<em  class="Sel" style="display: block;min-height: 400px;max-width: 800px;">
						<NewsContent v-if="ShowContent" :content="Content" @UpNewsContent="UpNewsContent"></NewsContent>
					</em>
				</li>
				
				
				<li class="Sel" v-if="News.Id != 'add'">
					<span class="Sel">状态</span>
					<em class="Sel">
						<el-select placeholder="设置状态" v-model="News.Status">
							<el-option label="展示中" :value="50"></el-option>
							<el-option label="不展示" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">排序权重</span>
					<em class="Sel">
						<el-input-number style="width: 100px;" :min="1" :max="9999" :controls="false" v-model="News.Order"></el-input-number>
					</em>
					<i>权重值越大排名越靠前</i>
				</li>
				
				<li v-if="News.Id != 'add'" class="Sel">
					<span class="Sel">创建时间</span>
					<em class="Sel">
						{{News.CreatedAt}}
					</em>
				</li>
				
				<li v-if="News.Id != 'add'" class="Sel">
					<span class="Sel">最后修改时间</span>
					<em class="Sel">
						{{News.UpdatedAt}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel"></span>
					<em class="Sel">
						<el-button @click="UpdateNews()" type="warning">{{News.Id == 'add' ? '添加':'修改'}}</el-button>
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import {Select,Option,Dialog,Upload,InputNumber} from 'element-ui'
	import VueQuillEditor from 'vue-quill-editor'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
		import NewsContent from '@/components/news/NewsContent.vue'
	Vue.use(VueQuillEditor)
	export default {
	  name: 'NewsInfo',
	  props: {
	  },
	  data() {
	      return {
			  News:{
				  Id:'',
				  Title:'',
				  SubTitle:'',
				  Order:0,
				  Platform:'',
				  CategoryId:'',
				  Detail:'',
				  Thumb:'',
				  CreatedAt:'',
				  UpdatedAt:'',
				  Status:50,
			  },
			  Content:'',
			  ShowContent:false,
			  CategoryList:[],
			  editorOption:{},
			  uploadModel:'single',//multiple 多个
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
		'el-input-number':InputNumber,
		NewsContent
	  },
	  created() {
		this.GetCategoryList()
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少资讯ID，页面无法工作')
			return
		}
		this.News.Id = this.$route.params.Id
		if(this.News.Id != 'add'){
			this.GetNews(this.$route.params.Id)
			return
		}
		this.ShowContent = true
	  },
	  methods:{
		  UpNewsContent(_val){
		  	this.Content = _val
		  },
		  getFilesChange(file, fileList) {
			  this.uploadModel = 'multiple'
				this.getBase64(file.raw).then(res => {
					this.uploadPic(res)
				});
		  },
		  getFile(file, fileList) {
			this.uploadModel = 'single'
			this.getBase64(file.raw).then(res => {
				this.uploadPic(res)
			});
		  },
		  getBase64(file) {
		        return new Promise(function(resolve, reject) {
		          let reader = new FileReader();
		          let imgResult = "";
		          reader.readAsDataURL(file);
		          reader.onload = function() {
		            imgResult = reader.result;
		          };
		          reader.onerror = function(error) {
		            reject(error);
		          };
		          reader.onloadend = function() {
		            resolve(imgResult);
		          };
		        });
		      },
		  			  uploadPic(_b64){
		  				  let that = this
		  				  let data = {
		  				  	Service:'Goods',
		  				  	Class: 'File',
		  				  	Action: 'Base64Upload',
		  				  	Base64:_b64
		  				  }
		  				  this.$post(that.$store.getters.getApiHost,data)
		  				  .then((res) => {
		  				  	
		  				  	if(res.ErrorId != 0){
		  				  		that.$message(res.Msg)
		  				  		return
		  				  	}
		  				  	
							that.News.Thumb = res.Data.Url
		  				  
		  				  	
		  				  })
		  				  .catch(function (response) {
		  				  	that.DialogMsg = '网络请求错误'
		  				  })
		  			  },
		    GetNews(_id){
		  		let data = {Service:'News',Class: 'News',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.News = res.Data
					this.Content = this.News.Detail
					this.ShowContent = true
		  		})
		    },
			handleRemove(file, fileList) {
			    console.log(file, fileList);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},	  
			UpdateNews(){
				let data = {
					Service:'News',
					Class: 'News',
					Action: 'Update',
					Id:this.News.Id,
					Platform:this.News.Platform,
					Status:this.News.Status,
					Title:this.News.Title,
					SubTitle:this.News.SubTitle,
					Order:parseInt(this.News.Order),
					Thumb:this.News.Thumb,
					CategoryId:this.News.CategoryId,
					Detail:this.Content
				}
				if(this.News.Id == 'add'){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0 && this.News.Id == 'add'){
						this.$Jump('/do/news/list')
					}else if(res.ErrorId == 0){
						this.GetNews(this.News.Id)
					}
				})
			},
			GetCategoryList(){
					let data = {
						Service:'News',
						Class: 'NewsCategory',
						Action: 'List',
						Page:1,
						PageSize:20,
					}
					this.$post(this.$store.getters.getApiHost,data)
					.then((res) => {
						
						if(res.ErrorId != 0){
							this.$message(res.Msg)
							return
						}
						this.CategoryList = res.Data.NewsCategoryList
						
					})
					.catch(function (response) {
						this.$message("网络请求错误")
					})
			}
	  }
	}
</script>

<style>
.GoodsInfoSSS{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.GoodsInfoSSS .Top{
	display: flex;
	align-items: center;
}
.GoodsInfoSSS .Top .Left{
	
}
.GoodsInfoSSS .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.GoodsInfoSSS .List{
	margin-top: 20px;	
}
.GoodsInfoSSS .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.GoodsInfoSSS .List .One li.Sel{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.GoodsInfoSSS .List .One li.Sel span.Sel{
	width: 80px;
	color: rgba(0,0,0,0.3);
}
.GoodsInfoSSS .List .One li.Sel  em.Sel{
	flex: 1;
}
.GoodsInfoSSS .List .One li.Sel i.Sel{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  
.ql-editor{
        height:360px;
    }
</style>
